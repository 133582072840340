import {
  Icons,
  ShapeIcon,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  TextsContainer,
  CardsContainer,
  StyledButton,
  TopicText,
  CardContainer,
  StyledDot,
} from './styles';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useAuthNavigation } from 'src/routes';
import { Auth } from '@flash-tecnologia/hros-web-utility';
import { setPageTracking } from '@/utils/analytics/segment';
import { useEffect } from 'react';

type accessType = 'oldAccess' | 'firstAccess';

export const AreYouNewHereCompanyStep = () => {
  const navigate = useAuthNavigation();
  const { step, setStep } = useFirstAccessContext();

  const onSelectAccessType = async (accessType: accessType) => {
    if (accessType === 'oldAccess') {
      await Auth.signOut();
      navigate('LOGIN', { replace: false });
    } else {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    setPageTracking('authentication_firstaccess_company_page');
  }, []);

  return (
    <>
      <TextsContainer>
        <Typography variant="headline5">
          Dê o primeiro passo para uma experiência com mais liberdade!
        </Typography>
        <Typography
          variant="body3"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Antes de começarmos o <strong>processo de contratação</strong>,
          precisamos saber: você já possui uma conta Flash?
        </Typography>
      </TextsContainer>
      <CardsContainer>
        <CardsContainer>
          <CardContainer>
            <ShapeIcon
              variant="default"
              name="IconKeyOff"
              size={56}
              color="var(--color-secondary-50)"
              style={{ flexShrink: 0 }}
            />
            <Typography variant="headline7" variantColor="var(--color-primary)">
              Não tenho conta na Flash
            </Typography>
            <TopicText variant="body3" variantColor="var(--color-neutral-40)">
              <StyledDot variant="pink" />
              Nunca utilizei a Flash em outra empresa ou
            </TopicText>
            <TopicText variant="body3" variantColor="var(--color-neutral-40)">
              <StyledDot variant="pink" />
              Não finalizei a criação da conta Flash no fluxo de contratação
              <Tooltip title="Não confirmou o celular no primeiro passo da contratação.">
                <span style={{ alignSelf: 'center' }}>
                  <Icons name="IconInfoCircle" size={16} />
                </span>
              </Tooltip>
            </TopicText>
            <StyledButton
              size="large"
              variant="primary"
              onClick={() => onSelectAccessType('firstAccess')}
            >
              Continuar
              <Icons name="IconArrowRight" size={24} />
            </StyledButton>
          </CardContainer>
          <CardContainer>
            <ShapeIcon
              variant="default"
              name="IconKey"
              size={56}
              color="var(--color-secondary-50)"
              style={{ flexShrink: 0 }}
            />
            <Typography variant="headline7" variantColor="var(--color-primary)">
              Tenho conta na Flash
            </Typography>
            <TopicText variant="body3" variantColor="var(--color-neutral-40)">
              <StyledDot variant="pink" />
              Já utilizei a Flash em outra empresa ou
            </TopicText>
            <TopicText variant="body3" variantColor="var(--color-neutral-40)">
              <StyledDot variant="pink" />
              Finalizei a criação da conta Flash no fluxo de contratação
              <Tooltip title="Confirmou o celular no primeiro passo da contratação.">
                <span style={{ alignSelf: 'center' }}>
                  <Icons name="IconInfoCircle" size={16} />
                </span>
              </Tooltip>
            </TopicText>
            <StyledButton
              size="large"
              variant="secondary"
              onClick={() => onSelectAccessType('oldAccess')}
            >
              Acessar minha conta
              <Icons name="IconArrowRight" size={24} />
            </StyledButton>
          </CardContainer>
        </CardsContainer>
      </CardsContainer>
    </>
  );
};
