import { useCallback, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { CardWithIcon } from '../../../../components/Common';
import {
  ButtonsContainer,
  CardsButtonContainer,
  CardsContainer,
  GreetingsText,
  LinkButtonStyled,
  StyledButton,
  SubTitle,
  TextsContainer,
  Title,
} from './styles';
import { useValidateInvitationToken } from './hooks';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useAuthNavigation } from 'src/routes';
import { Auth } from '@flash-tecnologia/hros-web-utility';
import { useCurrentAuthenticatedUser } from '@/auth';

type accessType = 'oldAccess' | 'firstAccess' | null;

export const AreYouNewHereStep = () => {
  const { user } = useCurrentAuthenticatedUser();
  const [accessType, setAccessType] = useState<accessType>(null);
  const { step, setStep, invitationToken } = useFirstAccessContext();
  const navigate = useAuthNavigation();
  const { isLoading } = useValidateInvitationToken(invitationToken);

  const onConfirm = useCallback(async () => {
    if (accessType === 'oldAccess') {
      await Auth.signOut();
      navigate('LOGIN', { replace: false });
    } else {
      setStep(step + 1);
    }
  }, [accessType, navigate, step, setStep]);

  return (
    <>
      <TextsContainer>
        <GreetingsText variant="body3">{`Olá${user ? `, ${user.attributes?.name}` : ''}!`}</GreetingsText>
        <Title variant="headline5">
          Escolha uma das opções abaixo para acessar sua empresa
        </Title>
        <SubTitle variant="body3">
          Sua rotina mais cor de rosa está a poucos passos de você.
        </SubTitle>
      </TextsContainer>
      <CardsButtonContainer>
        <CardsContainer>
          <CardWithIcon
            iconName="IconTrophy"
            title="É minha primeira vez na Flash!"
            subTitle={
              'Nunca teve um cartão Flash? Escolha esta opção para criar sua conta! \n\nEla será usada para acessar sua conta em todas as empresas que oferecem Flash onde você trabalhar no futuro.'
            }
            selected={accessType === 'firstAccess'}
            onClick={() => setAccessType('firstAccess')}
          />
          <CardWithIcon
            iconName="IconLogin"
            title="Já usei Flash antes"
            subTitle={
              'Se você já usou Flash em outra empresa, acesse sua conta com o CPF e a mesma senha. \n\nSua conta é única e funcionará em todas as empresas com Flash.'
            }
            selected={accessType === 'oldAccess'}
            onClick={() => setAccessType('oldAccess')}
          />
        </CardsContainer>
        <ButtonsContainer>
          <LinkButtonStyled variant="default" onClick={() => setStep(step - 1)}>
            Voltar
          </LinkButtonStyled>
          <StyledButton
            variant="primary"
            size="large"
            disabled={!accessType || isLoading}
            loading={isLoading}
            onClick={onConfirm}
          >
            Continuar <Icons name="IconArrowRight" />
          </StyledButton>
        </ButtonsContainer>
      </CardsButtonContainer>
    </>
  );
};

export const AreYouNewHereStepOld = () => {
  const { code } = useParams();
  return (
    <Navigate
      to={`/authentication/first-access?invitationToken=${code}`}
      replace
    />
  );
};
