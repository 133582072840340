import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { dispatchToast, validateCPF } from '../../../../utils';
import { useAuthContext } from '@auth/index';
import { useCallback, useState } from 'react';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useSearchParams } from 'react-router-dom';
import { CustomMfa } from '@/auth/models/SessionUserModel';
import { trpc } from '@/api/client';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import metadata from 'libphonenumber-js/metadata.min.json';

const validCountryCodes = new Set(
  Object.values(metadata.countries).map(([code]) => code),
);

export const useCreateAccountForm = () => {
  const { setStep, step, setFormData, invitationToken } =
    useFirstAccessContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPassport, setIsPassport] = useState<boolean>(false);
  const auth = useAuthContext();
  const [searchParams] = useSearchParams();
  const mfaMethod = searchParams.get('mfaMethod') as CustomMfa | undefined;

  const { mutateAsync: validateInvitationMutation } =
    trpc.validateInvitationToken.useMutation({
      onError: () => {
        throw new Error(
          'Ops! Erro ao processar dados, favor tentar novamente!',
        );
      },
    });

  const form = useFormik({
    initialValues: {
      name: '',
      documentNumber: '',
      email: '',
      phoneNumber: '+55',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    validate: (values: FormValues) => {
      const errors: any = {};
      if (
        values.documentNumber &&
        !validateCPF(values.documentNumber) &&
        !isPassport
      )
        errors.documentNumber = 'Favor digitar um CPF válido.';

      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber);
      if (!phoneNumber || !phoneNumber.isValid()) {
        errors.phoneNumber = 'Favor digitar um telefone válido.';
      } else if (!validCountryCodes.has(phoneNumber.countryCallingCode)) {
        errors.phoneNumber =
          'O DDI informado não é válido. Exemplo de DDI: +55';
      }

      return errors;
    },
    onSubmit: async (values: FormValues) => {
      setIsLoading(true);
      try {
        await validateInvitationToken(
          values.documentNumber.replace(/[^0-9a-zA-Z]/g, ''),
        );
        await signUp(values);
      } catch (error) {
        dispatchToast({
          type: 'error',
          content: error?.message,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const validateInvitationToken = useCallback(
    async (validationAttribute: string) => {
      if (!invitationToken) {
        return;
      }
      const { isValid } = await validateInvitationMutation({
        invitationToken,
        validationAttribute,
      });
      if (!isValid) {
        throw new Error(
          'Dados inválidos. Confira novamente os dados preenchidos e tente novamente.',
        );
      }
    },
    [invitationToken],
  );

  const signUp = async (values: FormValues) => {
    try {
      const username = uuidv4();
      await auth.signUp({
        password: values.password,
        phoneNumber: `+${values.phoneNumber.replace(/\D/g, '')}`,
        name: values.name,
        email: values.email,
        username,
        customMfa: mfaMethod,
      });
      setFormData({ username, ...values });
      setStep(step + 1);
    } catch {
      throw new Error('Ops! Erro ao processar dados, favor tentar novamente!');
    }
  };

  /**
   * Formats the input string into a CPF format.
   *
   * This function:
   * - Removes all non-digit characters.
   * - Limits the result to a maximum of 11 digits.
   * - Inserts periods and a dash to format the CPF as "000.000.000-00".
   *
   * @param {string} value - The input string to be formatted.
   * @returns {string} - The formatted CPF string.
   */
  const formatCPF = (value) => {
    const digits = value.replace(/\D/g, '').slice(0, 11);
    return digits.replace(
      /(\d{3})(\d{0,3})(\d{0,3})(\d{0,2})/,
      (_, p1, p2, p3, p4) => {
        let formatted = p1;
        if (p2) formatted += `.${p2}`;
        if (p3) formatted += `.${p3}`;
        if (p4) formatted += `-${p4}`;
        return formatted;
      },
    );
  };

  /**
   * Handles the onChange event for the input field.
   *
   * This function manually applies a CPF mask when the input value starts with a digit,
   * indicating that the user is entering a CPF. This manual masking is necessary because
   * we now allow both CPF and passport numbers as input; only CPF numbers should be masked.
   *
   * If the input doesn't start with a digit (i.e., it might be a passport number), the value
   * is left unformatted.
   *
   * @param {Object} e - The event object from the input field.
   */
  const onDocumentChange = (e) => {
    let value = e.target.value;

    // If the value starts with a digit, assume it's a CPF and apply the CPF mask.
    if (value && /^\d/.test(value)) {
      value = formatCPF(value);
      setIsPassport(false);
    } else {
      setIsPassport(true);
    }

    // Create a synthetic event that matches Formik's expected event structure.
    const syntheticEvent = {
      target: {
        name: e.target.name,
        value: value,
      },
    };

    form.handleChange(syntheticEvent);
  };

  /**
   * onPhoneChange processes user input:
   * - It updates the raw input state.
   * - It attempts to parse the phone number using libphonenumber-js.
   * - If parsing is successful, it formats the number into international format.
   *
   * This approach allows for flexibility since international phone numbers can vary
   * widely in format and length, making a fixed mask impractical.
   *
   * @param {Object} e - The change event from the input field.
   */
  const onPhoneChange = (e) => {
    let value = e.target.value;
    if (value && !value.startsWith('+')) {
      value = '+' + value;
    }

    const phoneNumber = parsePhoneNumberFromString(value);
    const newValue = phoneNumber ? phoneNumber.formatInternational() : value;

    form.handleChange({ target: { name: e.target.name, value: newValue } });
  };

  return { form, isLoading, onDocumentChange, isPassport, onPhoneChange };
};

type FormValues = {
  name: string;
  documentNumber: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
};

const validationSchema = yup.object({
  name: yup.string().required('Favor digitar um nome'),
  documentNumber: yup.string().required('Favor digitar um CPF'),
  email: yup.string().email('Digite um email válido').notRequired(),
  phoneNumber: yup.string().required('Favor digitar um celular válido'),
  password: yup
    .string()
    .min(8)
    .matches(RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/))
    .required('Favor digitar uma senha'),
  passwordConfirmation: yup
    .string()
    .required('Favor confirmar a sua senha')
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser idênticas'),
});
