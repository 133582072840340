import styled from 'styled-components';
import { ButtonBase } from '@mui/material';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

const IconAndRadioContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  position: relative;
`;

const CardContainer = styled(ButtonBase)<{ selected: boolean }>`
  max-width: 308px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.secondary[99] : theme.colors.neutral[100]};
  padding: 32px;
  gap: 8px;
  text-align: left;
  border-radius: 12px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.secondary[80] : theme.colors.neutral[90]};
`;

const SubTitle = styled(Typography)`
  && {
    white-space: pre-line;
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    font-weight: 600;
  }
`;

export { CardContainer, SubTitle, IconAndRadioContainer };
