import { useCallback, useEffect, useState } from 'react';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  ButtonsContainer,
  CardsButtonContainer,
  OptionsContainer,
  LinkButtonStyled,
  Option,
  StyledButton,
  SubTitle,
  TextsContainer,
  Title,
} from './styles';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useAuthNavigation } from 'src/routes';
import { Auth } from '@flash-tecnologia/hros-web-utility';
import { Radio } from '@mui/material';
import { useValidateInvitationToken } from '../AreYouNewHereStep/hooks';
import { setPageTracking } from '@/utils/analytics/segment';

type accessType = 'oldAccess' | 'firstAccess' | null;

export const AreYouNewHereSimplifiedStep = () => {
  const [accessType, setAccessType] = useState<accessType>(null);
  const { step, setStep, invitationToken } = useFirstAccessContext();
  const navigate = useAuthNavigation();
  const { isLoading } = useValidateInvitationToken(invitationToken);

  const onBack = () => {
    history.back();
  };

  const onConfirm = useCallback(async () => {
    if (accessType === 'oldAccess') {
      await Auth.signOut();
      navigate('LOGIN', { replace: false });
    } else {
      setStep(step + 1);
    }
  }, [accessType, navigate, step, setStep]);

  useEffect(() => {
    setPageTracking('authentication_firstaccess_simplified_page');
  }, []);

  return (
    <>
      <TextsContainer>
        <Title variant="headline7" color="neutral.30">
          Você já usou Flash em alguma empresa?
        </Title>
        <SubTitle variant="body3" color="neutral.40">
          A partir daqui, vamos cadastrar seus dados pessoais para que você
          possa administrar a conta dessa empresa! Porém, caso já tenha usado
          Flash em outra empresa, é só fazer o login na mesma conta.
        </SubTitle>
      </TextsContainer>
      <CardsButtonContainer>
        <OptionsContainer>
          <Option
            selected={accessType === 'oldAccess'}
            onClick={() => setAccessType('oldAccess')}
          >
            <Radio
              checked={accessType === 'oldAccess'}
              style={{ width: '24px', height: '24px' }}
            />
            <Typography weight={700} variant="body4" color="neutral.30">
              Sim, já usei!
            </Typography>
          </Option>
          <Option
            selected={accessType === 'firstAccess'}
            onClick={() => setAccessType('firstAccess')}
          >
            <Radio
              checked={accessType === 'firstAccess'}
              style={{ width: '24px', height: '24px' }}
            />
            <Typography weight={700} variant="body4" color="neutral.30">
              Não, nunca usei Flash
            </Typography>
          </Option>
        </OptionsContainer>
        <ButtonsContainer>
          <LinkButtonStyled variant="default" onClick={onBack}>
            Voltar
          </LinkButtonStyled>
          <StyledButton
            variant="primary"
            size="large"
            disabled={!accessType || isLoading}
            loading={isLoading}
            onClick={onConfirm}
          >
            Continuar <Icons name="IconArrowRight" />
          </StyledButton>
        </ButtonsContainer>
      </CardsButtonContainer>
    </>
  );
};
