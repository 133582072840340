import React from 'react';
import { CardContainer, IconAndRadioContainer, SubTitle } from './styles';
import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { iconsOptions } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Icons';
import { Radio } from '@mui/material';

type CardProps = {
  iconName: (typeof iconsOptions)[number];
  title: string;
  selected?: boolean;
  subTitle: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  value?: string | number | readonly string[];
};

export const CardWithIcon = ({
  iconName,
  title,
  subTitle,
  selected = false,
  onClick,
  value,
}: CardProps) => {
  return (
    <CardContainer selected={selected} onClick={onClick} value={value}>
      <IconAndRadioContainer>
        <ShapeIcon
          size={48}
          name={iconName}
          variant="default"
          stroke="default"
        />
        <Radio
          checked={selected}
          style={{
            width: '24px',
            height: '24px',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />
      </IconAndRadioContainer>
      <Typography
        weight={700}
        variant="headline9"
        style={{ marginTop: '12px' }}
      >
        {title}
      </Typography>
      <SubTitle variant="body4">{subTitle}</SubTitle>
    </CardContainer>
  );
};
