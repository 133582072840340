import styled from 'styled-components';
import {
  Button,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

export const GreetingsText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 568px;
`;

export const Title = styled(Typography)``;

export const SubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const CardsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 870px;
  margin-top: 40px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 82px;
`;

export const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      padding: 19px 46px;
    }
    @media screen and (max-width: 420px) {
      width: 100%;
      align-items: center;
    }
  }
`;
