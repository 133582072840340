import styled from 'styled-components';
import {
  Button,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ButtonBase } from '@mui/material';

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
`;

export const Title = styled(Typography)``;
export const SubTitle = styled(Typography)``;

export const CardsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 870px;
  margin-top: 40px;
`;

export const OptionsContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Option = styled(ButtonBase)<{ selected: boolean }>`
  display: flex;
  justify-content: flex-start;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.secondary[99] : theme.colors.neutral[100]};
  padding: 16px;
  gap: 8px;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.secondary[80] : theme.colors.neutral[90]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 550px;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 82px;
`;

export const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      padding: 19px 46px;
    }
    @media screen and (max-width: 420px) {
      width: 100%;
      align-items: center;
    }
  }
`;
